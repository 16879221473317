import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders,Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DsLoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { NbAuthComponent } from './auth.component';
import {
  NbInputModule,
  NbLayoutModule,
  NbCardModule,
  NbCheckboxModule,
  NbAlertModule,
  NbButtonModule,
  NbIconModule,
} from '@nebular/theme';
import { NbTokenService } from './services/token/token.service';
import { NbAuthService } from './services/auth.service';
import { NbTokenLocalStorage, NbTokenStorage } from './services/token/token-storage';
import { NbAuthTokenParceler, NB_AUTH_FALLBACK_TOKEN } from './services/token/token-parceler';
import { NbAuthOptions,NB_AUTH_OPTIONS, NB_AUTH_USER_OPTIONS, defaultAuthOptions, NB_AUTH_INTERCEPTOR_HEADER, NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from './auth.options';


import { NbAuthStrategyOptions } from './strategies/auth-strategy-options';
import { NB_AUTH_TOKENS, NB_AUTH_STRATEGIES} from './auth.options';
import { NbDummyAuthStrategy } from './strategies/dummy/dummy-strategy';
import { NbPasswordAuthStrategy } from './strategies/password/password-strategy';
import { NbOAuth2AuthStrategy } from './strategies/oauth2/oauth2-strategy';
import { NbAuthStrategy } from './strategies/auth-strategy';

import { NbAuthTokenClass, NbAuthSimpleToken } from './services/token/token';
import { HttpRequest } from '@angular/common/http';
import { deepExtend } from './helpers';
import { NonAuthGuardService, AuthUserGuard } from '../../services/AuthGuard';

const routes: Routes = [{
  path: '',
  component: NbAuthComponent,
  children: [
    {
      path: '',
      component: DsLoginComponent,
    },
    {
      path: 'login',
      component: DsLoginComponent,
      canActivate: [NonAuthGuardService] // ServerCheckGuardService
    }
  ],
}];
const config: ExtraOptions = {
  useHash: false,
  relativeLinkResolution: 'legacy'
};

export type NbAuthStrategyClass = new (...params: any[]) => NbAuthStrategy;
export type NbAuthStrategies = [NbAuthStrategyClass, NbAuthStrategyOptions][];



export function nbStrategiesFactory(options: NbAuthOptions, injector: Injector): NbAuthStrategy[] {
  const strategies = [];
  options.strategies
    .forEach(([strategyClass, strategyOptions]: [NbAuthStrategyClass, NbAuthStrategyOptions]) => {
      const strategy: NbAuthStrategy = injector.get(strategyClass);
      strategy.setOptions(strategyOptions);

      strategies.push(strategy);
    });
  return strategies;
}

export function nbTokensFactory(strategies: NbAuthStrategy[]): NbAuthTokenClass[] {
  const tokens = [];
  strategies
    .forEach((strategy: NbAuthStrategy) => {
      tokens.push(strategy.getOption('token.class'));
    });
  return tokens;
}




export function nbOptionsFactory(options) {
  return deepExtend(defaultAuthOptions, options);
}

export function nbNoOpInterceptorFilter(req: HttpRequest<any>): boolean {
  return true;
}

@NgModule({
  declarations: [
    DsLoginComponent,
    NbAuthComponent
  ],
  imports: [
    CommonModule,
    NbLayoutModule,
    NbCardModule,
    NbCheckboxModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    RouterModule,
    FormsModule,
    NbIconModule,
    FormsModule,
    RouterModule.forChild(routes)
  ],
  providers: [
    NbTokenService,
    NbAuthTokenParceler,
    NbAuthService,
    NonAuthGuardService,
    AuthUserGuard,
    { provide: NbTokenStorage, useClass: NbTokenLocalStorage },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AuthModule {
  static forRoot(nbAuthOptions?: NbAuthOptions): ModuleWithProviders {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: NB_AUTH_USER_OPTIONS, useValue: nbAuthOptions },
        { provide: NB_AUTH_OPTIONS, useFactory: nbOptionsFactory, deps: [NB_AUTH_USER_OPTIONS] },
        { provide: NB_AUTH_STRATEGIES, useFactory: nbStrategiesFactory, deps: [NB_AUTH_OPTIONS, Injector] },
        { provide: NB_AUTH_TOKENS, useFactory: nbTokensFactory, deps: [NB_AUTH_STRATEGIES] },
        { provide: NB_AUTH_FALLBACK_TOKEN, useValue: NbAuthSimpleToken },
        { provide: NB_AUTH_INTERCEPTOR_HEADER, useValue: 'Authorization' },
        { provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER, useValue: nbNoOpInterceptorFilter },
        { provide: NbTokenStorage, useClass: NbTokenLocalStorage },
        NbAuthTokenParceler,
        NbAuthService,
        NbTokenService,
        NbDummyAuthStrategy,
        NbPasswordAuthStrategy,
        NbOAuth2AuthStrategy
      ],
    };
  }
}

import { CommonResponse } from '../CommonResponse';


export interface IFilizDeviceModel {
    Id: number;
    IMEI?: string;
    IMSI?: string;
    IsActive: boolean;
    LineOwner: string;
    MSISDN?: string;
    Notes: string;
    ResponseValue: string;
    UnderSowingRHConstant: number;
    AppendixA: number;
    AppendixB: number;
    AppendixC: number;
    AppendixD: number;
    DeviceId: string;
    DeviceOwner: number;
    ExpValue: string;
    Version: string;
    Battery: number;
    GsmInfo: string;
    GsmMode: string;
    CpuTemperature: number;
    LastDataDate?: Date;
    GeoItemStr: string;
    LastMeasurementDate?: Date;
    SimCardId: number;
    InsectType?: any;
}
export interface IFilizDevicePostOptions extends IFilizDeviceModel { }


export interface IFilizDeviceLocationUpdateOptions {
    lat: number;
    lng: number;
}

export interface IFilizDeviceListResponse<T> extends CommonResponse {
    Data: T[];
}


export interface IFilizDeviceBatchPostModel {
    Id?: number;
    BaseBatchNum: number;
    StartBatchNum: number;
    EndBatchNum: number;
    WeatherSensor1: string;
    WeatherSensor2: string;
    MainBoardPCB: string;
    ModemFirmware: string;
    SoilTempSensor: string;
    SoilHumiditySensor: string;
    SoilHumidityPCB: string;
    Antenna: string;
    UnderSowingRHConstant: number;
    ResponseValue: number;
    AppendixA: number;
    AppendixB: number;
    AppendixC: number;
    AppendixD: number;
    ExpValue: number;
    DeviceCount: number;
}
export interface IFilizDeviceBatchPostOptions extends IFilizDeviceBatchPostModel { }

export interface PhenologyCameraRequest {
    Id: number
    Antenna: string
    BatchId: number
    ButtonType: string
    ChargeCard: string
    Color: string
    ConnectorType: string
    DeviceId: string
    DeviceOwner: number
    DeviceState: number
    LicenceCode: string
    LineOwner: boolean
    LineOwnerId: number
    MSISDN: string
    MainBoardPCB: string
    ModemFirmware: string
    Notes: string
    PayerId: number
    PurchaseOn: string
    ResponseValue: string
    SimCardId: number
    SolarPanelVersion: string
    WarrantyDate: string
    DeviceVersion: string
    IsActive: boolean
    ShippingDate: string
    ShippingAddress: string
}



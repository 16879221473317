
export class FilizDeviceMdel {
    Id: number;
    DeviceId: string;
    ResponseValue: string;
    DeviceState: number;
    IsActive: boolean;
    AlarmThresholdValue: number;
    NextAlarmDate: Date;
    UnderSowingRHConstant: number;
    IsIrrigationControl: boolean;
    UnderSoilCalculationType: number;
    AppendixA: number;
    AppendixB: number;
    AppendixC: number;
    AppendixD: number;
    DeviceOwner: number;
    IMEI: string;
    IMSI: string;
    MSISDN: string;
    LineOwner: any;
    Notes: string;
    LastDataDate: Date | null;
    GeoItem: any;
    GsmInfo: string;
    CpuTemperature: number | null;
    Battery: number | null;
    GsmMode: string | null;
    DefectCode?: string;
    ICCID: string;
    LineOwnerInfo: string;
    GeoItemStr: string;
    DeviceOwnerStr: string;
    ExpireDate: Date | null;
    PayerId: Number | null;
    UserRoleId: Number | null;
    PurchasedOn: Date | null;
    TariffName: string;
    TariffId: number;
    SimCardId: number;
    SimCardName: string;
    BatchId: number;
    RentalPeriod:number;
    QuotaTypeId:number;
    HardwareVersion:number;
}

export class FilizDeviceMapModel extends FilizDeviceMdel {
    GeoData: any;
}

export class FilizBatchModel {
    constructor() {
        this.Id = 0;
    }
    Id: number;
    BaseBatchNum: number;
    StartBatchNum: number;
    EndBatchNum: number;
    WeatherSensor1: string;
    WeatherSensor2: string;
    MainBoardPCB: string;
    ModemFirmware: string;
    SoilTempSensor: string;
    SoilHumiditySensor: string;
    SoilHumidityPCB: string;
    Antenna: string;
    UnderSowingRHConstant: number;
    ResponseValue: number;
    AppendixA: number;
    AppendixB: number;
    AppendixC: number;
    AppendixD: number;
    ExpValue: number;
    DeviceCount: number;
    // "Id": 0,
    // "BaseBatchNum": 1201,
    // "StartBatchNum": 1000,
    // "EndBatchNum": 2000,
    // "WeatherSensor1": "test",
    // "WeatherSensor2": "test",
    // "MainBoardPCB": "test",
    // "ModemFirmware": "test",
    // "SoilTempSensor": "test",
    // "SoilHumiditySensor": "test",
    // "SoilHumidityPCB": "test",
    // "Antenna": "test",
    // "UnderSowingRHConstant":1,
    // "ResponseValue":1,
    // "AppendixA":1,
    // "AppendixB":1,
    // "AppendixC":1,
    // "AppendixD":1,
    // "ExpValue":1,
    // "DeviceCount":3
}

export class FilizGroupSummaryModel {
    Title: string;
    Quantity: number;
    Tooltip: string;
    IdList: string;
    Condition: string;
    Id: number;
    ParentId: number;
}

export interface IGetFilizGroupOptions {
    IdList?: number[];
}

export interface FilizDashboardItem {
    // Title: string;
    // Data: FilizDashboardItemList[];
    Id: number;
    Information: string;
    Quantity: number;
    Title: string;
    ChartData: FilizDashboardItemList[];
}
export interface FilizDashboardItemList {
    Title: string;
    IdList: number[];
    Quantity: number;
    Id: number;
}
export interface DeviceMapResponse {
    Id: number;
    DeviceId: string;
    GeoJson: string;
    Feature: any;
}
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppConstants, version } from '../common/variable';
import { get } from 'lodash';
import { NbTokenService } from '../pages/auth/services/token/token.service';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { NbAuthService } from '../pages/auth/services/auth.service';
import 'rxjs/add/operator/catch';


@Injectable()
export class AuthUserGuard implements CanActivate, CanActivateChild {

  constructor(public router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (AppConstants.User && get(AppConstants.User, 'Token')) {
      return of(true);
    } else {
      this.router.navigate(['/auth/login']);
      return of(false);
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute, state);
  }
}


@Injectable()
export class NonAuthGuardService implements CanActivate {

  constructor(
    public router: Router,
    protected tokenService: NbTokenService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> {
    return new Observable((observer) => {
      this.tokenService.get().subscribe(
        (tokenResult) => {
          if (localStorage.getItem(AppConstants.Storage.User) || AppConstants.User) {
            this.router.navigate(['/']);
            return observer.next(false);
          }
          return observer.next(true);
        },
        () => {
          return observer.next(false);
        },
        () => {
          return observer.complete();
        });
    });
  }
}

@Injectable()
export class ServerCheckGuardService implements CanActivate, CanActivateChild {
  constructor(
    private authService: NbAuthService,
    private httpClient: HttpClient,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.getAppStatus()
      .pipe(
        map((response: any) => {
          if (response['version'].code > version.code) {
            location.reload();
            return false;
          }
          return true;
        })
      )

      .catch(error => {
        this.router.navigate(['/maintenance']);
        return of(false);
      });
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(next, state);
  }

  getAppStatus(): Observable<any> {
    return this.authService
      .checkAppStatus()
      .pipe(
        switchMap(response => {
          let isSubscriptionOn = false;
          let isWebOn = false;
          response.data.forEach(app => {
            if (app.application === 'Subscription') {
              isSubscriptionOn = app.status;
            } else if (app.application === 'Web') {
              isWebOn = app.status;
            }
          });

          if (isSubscriptionOn && isWebOn) {
            return this.httpClient.get(
              `assets/app.json?v=${new Date().getTime()}`
            );
          }
          return Observable.throw([]);
        })
      )
      .catch(error => {
        return Observable.throw([]);
      });
  }
}


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../common/variable';
import { ApiResponse } from '../common/model/ApiResponse';
import { QuotaRequestModel, QuotaResponseModel } from '../common/model/QuotaModel';
import { CropMapAccount, INewCropMapAccount } from '../common/model/CropMapAccount';
import { CropMapCitiesModel } from '../common/model/CropMapCitiesModel';
import { tap } from 'rxjs/operators';

interface ICurrentAccountItem {
  Id: number;
  AccountName: string;
  AccountCode: string;
  InvoiceAddress: string;
  TaxNumber: string;
  TaxOfficeName: string;
  RelatedPersonName: string;
  PhoneNumber: string;
  MailAddress: string;
  FilizUsageRightRemaining: number;
  OrbitUsageRightRemaining: number;
  PestTrappUsageRightRemaining: number;
  SoilAnalyseUsageRightRemaining: number;
}
@Injectable({
  providedIn: 'root',
})
export class CurrentAccountService {
  constructor(public http: HttpClient) {}

  getCurrentAccountList(pageCount = 0, takeCount = 15): Observable<any> {
    return this.http.get(AppConstants.Services.ApiService + `/api/checking/account/list?pageCount=${pageCount}&takeCount=${takeCount}`);
  }

  getCurrentAccountById(id: number): Observable<any> {
    return this.http.get(AppConstants.Services.ApiService + `/api/checking/account/${id}`);
  }

  postCurrentAccount(account: ICurrentAccountItem): Observable<any> {
    return this.http.post(AppConstants.Services.ApiService + `/api/checking/account/update`, account);
  }

  deleteAccount(account: ICurrentAccountItem): Observable<any> {
    return this.http.post(AppConstants.Services.ApiService + `/api/checking/account/remove?id=${account.Id}`, {});
  }

  setLogo(formData: any) {
    return this.http.post(AppConstants.Services.ApiService + '/api/upload/company/logo', formData);
  }

  getQuota(id): Observable<ApiResponse<QuotaResponseModel>> {
    return this.http.get<ApiResponse<QuotaResponseModel>>(AppConstants.Services.ApiService + `/api/get/account/quota?accountId=` + id);
  }

  getAvailableQuota(id: number, typeId: number): Observable<ApiResponse<any>> {
    return this.http.get<ApiResponse<any>>(
      AppConstants.Services.ApiService + `/api/get/account/available/quota?accountId=` + id + `&typeId=` + typeId,
    );
  }

  saveQuota(formData: any) {
    return this.http.post(AppConstants.Services.ApiService + '/api/add/account/quota', formData);
  }

  updateQuota(formData: QuotaRequestModel) {
    return this.http.post(AppConstants.Services.ApiService + '/api/update/account/quota', formData);
  }

  getCropMapReports(groupName: any): Observable<any> {
    const baseUrl = AppConstants.Services.ApiService;
    // const baseUrl = 'https://com-doktarim-api-v3-staging.azurewebsites.net';
    return this.http.post(baseUrl + `/v2/api/cropmap/get/report/list`, groupName);
  }

  saveCropMapReports(formData: any): Observable<any> {
    const baseUrl = AppConstants.Services.ApiService;
    // const baseUrl = 'https://com-doktarim-api-v3-staging.azurewebsites.net';
    return this.http.post(baseUrl + '/v2/api/cropmap/save/report', formData);
  }

  getCropMapCropList() {
    return this.http.get(AppConstants.Services.AzureBlobService + '/legacy/software/cropmap/constants/crop-list.json');
  }

  getCropMapSeasonList() {
    return this.http.get(AppConstants.Services.AzureBlobService + '/legacy/software/cropmap/constants/season-list.json');
  }

  getCropMapAccountConfigs(groupName: string, countryName: string) {
    return this.http
      .get(
        AppConstants.Services.AzureBlobService +
          '/legacy/software/cropmap/' +
          groupName +
          '/config.' +
          countryName.toLocaleLowerCase() +
          '.json?v=' +
          Math.random(),
      )
      .pipe(
        tap((res) => {
          if (res) {
            res['CountryName'] = countryName;
          }
        }),
      );
  }

  getCropMapAccountConfig(groupName: string) {
    return this.http.get(AppConstants.Services.AzureBlobService + '/legacy/software/cropmap/' + groupName + '/config.json?v=' + Math.random());
  }

  saveCropMapAccountConfigMulti(cropMapData: CropMapAccount) {
    let request = {
      data: JSON.stringify(cropMapData),
      groupName: cropMapData.GroupName,
      fileName: 'config.' + cropMapData.CountryName.toLocaleLowerCase() + '.json',
    };

    const baseUrl = AppConstants.Services.ApiService;
    // const baseUrl = 'https://com-doktarim-api-v3-staging.azurewebsites.net';
    return this.http.post(baseUrl + '/v2/api/cropmap/save/config', request);
  }

  saveCropMapAccountConfig(cropMapData: INewCropMapAccount) {
    let request = {
      data: JSON.stringify(cropMapData),
      groupName: cropMapData.group_name,
      fileName: 'config.json',
    };

    const baseUrl = AppConstants.Services.ApiService;
    // const baseUrl = 'https://com-doktarim-api-v3-staging.azurewebsites.net';
    return this.http.post(baseUrl + '/v2/api/cropmap/save/config', request);
  }

  getCropMapCities(groupName: string) {
    return this.http.get(
      AppConstants.Services.AzureBlobService + '/legacy/software/cropmap/' + groupName + '/Constants/cities.json?v=' + Math.random(),
    );
  }
  sendCropMapCities(cityData: CropMapCitiesModel, groupName: string) {
    let request = { data: JSON.stringify(cityData), groupName: groupName + '/Constants', fileName: 'cities.json' };
    const baseUrl = AppConstants.Services.ApiService;
    // const baseUrl = 'https://com-doktarim-api-v3-staging.azurewebsites.net';
    return this.http.post(baseUrl + '/v2/api/cropmap/save/config', request);
  }
  getCsiOperationList(groupName: string): Observable<any> {
    return this.http.get(AppConstants.Services.ApiService + `/v2/api/csi/companyseason/list?groupName=${groupName}`);
  }
  deleteCsi(id): Observable<any> {
    return this.http.post(AppConstants.Services.ApiService + `/v2/api/csi/delete/companyseason`, { id: id });
  }
  saveAndEditCsiOperation(data) {
    return this.http.post(AppConstants.Services.ApiService + `/v2/api/csi/save/companyseason`, data);
  }
}

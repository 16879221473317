<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <img style="width:125px;" src="/assets/images/doktar-logo.png" />
    </a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">

    <nb-action class="control-item">
      <!-- <nb-search type="rotate-layout"></nb-search> -->
    </nb-action>
    <nb-action class="control-item" (click)="goToInfo()" icon="credit-card-outline">
    </nb-action>
    <nb-action [badgeText]="bagdeCount" badgeStatus="danger" badgePosition="top left" class="control-item"
      icon="bell-outline" (click)="goToNotifications()"></nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.name">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>

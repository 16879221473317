import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../common/variable';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(public http: HttpClient) { }

    getExpireNotificationList(): Observable<any> {
        return this.http.get(AppConstants.Services.ApiService + `/api/get/expire/notification/list`);
    }
}


import { Injectable } from '@angular/core';

import { NbAuthToken } from './token';
import { NbAuthTokenParceler } from './token-parceler';

export abstract class NbTokenStorage {

  abstract get(): NbAuthToken;
  abstract set(token: NbAuthToken);
  abstract clear();
}

@Injectable()
export class NbTokenLocalStorage extends NbTokenStorage {

  protected key = 'auth_app_token';

  constructor(private parceler: NbAuthTokenParceler) {
    super();
  }

  /**
   * Returns token from localStorage
   * @returns {NbAuthToken}
   */
  get(): NbAuthToken {
    const raw = localStorage.getItem(this.key);
    return this.parceler.unwrap(raw);
  }

  /**
   * Sets token to localStorage
   * @param {NbAuthToken} token
   */
  set(token: NbAuthToken) {
    const raw = this.parceler.wrap(token);
    localStorage.setItem(this.key, raw);
  }

  /**
   * Clears token from localStorage
   */
  clear() {
    localStorage.removeItem(this.key);
  }
}

import { Injectable } from '@angular/core';
import { NbToastrService, NbComponentStatus, NbGlobalPhysicalPosition } from '@nebular/theme';
import { assign } from 'lodash';


export interface ToastrConfig {
  status: NbComponentStatus;
  destroyByClick: boolean;
  duration: number;
  hasIcon: boolean;
  position: NbGlobalPhysicalPosition;
  preventDuplicates: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DtToastrService {

  constructor(private toastrSrv: NbToastrService) { }

  defConfig: ToastrConfig = {
    duration: 3000,
    status: 'info',
    destroyByClick: true,
    hasIcon: false,
    position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
    preventDuplicates: false
  };

  show(message: string, title: string, config: Partial<ToastrConfig>) {
    this.toastrSrv.show(message, title, assign(this.defConfig, config));
  }

}

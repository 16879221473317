import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../common/variable';


@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(public http: HttpClient) { }

  cropList(): Observable<any> {
    return this.http.get(AppConstants.Services.ApiService + `/api/get/gp/crop/list`);
  }
  cropVarityList(cropId: number = 0): Observable<any> {
    return this.http.get(AppConstants.Services.ApiService + `/api/cropvariety/list?cropId=` + cropId);
  }
  getCityList(): Observable<any> {
    return this.http.get<any>(AppConstants.Services.ApiService + `/api/city/list`);
  }
  getDistrictList(cityId: number): Observable<any> {
    return this.http.get<any>(AppConstants.Services.ApiService + `/api/district/list/?cityId=` + cityId);
  }
  getVillageList(districtId: number): Observable<any> {
    return this.http.get<any>(AppConstants.Services.ApiService + `/api/village/list/?districtId=` + districtId);
  }
}

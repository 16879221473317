import * as Sentry from '@sentry/browser';
import { ErrorHandler, Injectable } from '@angular/core';
import { isDevMode } from '@angular/core';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error) {
    // Sentry.showReportDialog({ eventId });
    if (!isDevMode()) {
      const eventId = Sentry.captureException(error.originalError || error);
    }
    console.log(error);
    // throw error;
  }
}

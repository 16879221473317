import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, InjectionToken, NgZone } from '@angular/core';
import { NbAuthOptions, NbAuthSocialLink } from '../auth.options';
import { getDeepFromObject } from '../helpers';
import { NbAuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { DtServerResponse } from '../../../common/CommonResponse';
import { DtToastrService } from '../../../common/dt-toastr.service';
import { NbGlobalPhysicalPosition } from '@nebular/theme';
// import { getDeepFromObject } from '../../helpers';

// import { NbAuthService } from '../../services/auth.service';
// import { NbAuthResult } from '../../services/auth-result';

@Component({
  selector: 'nb-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DsLoginComponent {

  constructor(
    public authSvc: NbAuthService,
    private router: Router,
    private dtToastrSvc: DtToastrService,
    private ch: ChangeDetectorRef,
    private zone: NgZone) {
    // this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
    this.showMessages = true; // this.getConfigValue('forms.login.showMessages');
    // this.strategy = this.getConfigValue('forms.login.strategy');
    // this.socialLinks = this.getConfigValue('forms.login.socialLinks');
    // this.rememberMe = this.getConfigValue('forms.login.rememberMe');
  }

  redirectDelay: number = 0;
  showMessages: any = {};
  strategy: string = '';

  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  submitted: boolean = false;
  socialLinks: NbAuthSocialLink[] = [];
  rememberMe = false;
  options = new InjectionToken<NbAuthOptions>('Nebular Auth Options');

  login(): void {
    this.errors = [];
    this.messages = [];
    this.submitted = true;
    this.authSvc.doktarAuthenticate({
      'email': this.user.email,
      'password': this.user.password
    }).subscribe((data: any) => {
      if (data.IsSuccess) {
        this.dtToastrSvc.show('Success...', '', { status: 'success' });
        setTimeout(() => {
          this.router.navigateByUrl(`/pages/information/dash`);
        }, 500);

      } else {
        this.dtToastrSvc.show(data.Message, '', { status: 'danger', position: NbGlobalPhysicalPosition.TOP_RIGHT });
        this.submitted = false;
        this.ch.detectChanges();
      }
    }, (error: any) => {
      console.log(error);
    }, () => {
      this.submitted = false;
      setTimeout(() => {
        this.zone.run(() => { });
      }, 50);
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
